<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`Applications`) }}</h1>

                        </b-col>
                    </b-row>
                    <div class="mb-1">
            <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
              {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
              {{ i18nT(`entries`) }}</span>
                    </div>
                    <div>
                        <b-row class="mt-2 ml-0 mr-0 mb-2">
                            <b-col
                                v-for="stage in stages"
                                :key="stage.label"
                                class="m-0 p-0 stage-container"
                            >
                                <b-button
                                    class="w-100 h-100 p-0 pt-1 pb-1 stageLink"
                                    :to="{ name: 'applications', params: { stage: stage.short || stage.label.toUpperCase() } }"
                                    v-bind:class="[
                                        matchStage && (matchStage.toUpperCase() == stage.short.toUpperCase() || matchStage.toUpperCase() == stage.label.toUpperCase()) || (matchStage === 'ALL' && !stage.type)
                                          ? 'active-stage'
                                          : '',
                                      ]"
                                    variant="outline-secondary"
                                >
                                    <div class="count mb-50">{{ stage.count }}</div>
                                    <div class="stage-avatar">
                                        {{ stage.label.toLowerCase()[0] }}
                                    </div>
                                    <div class="stage-label">{{ stage.label }}</div>
                                </b-button>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col
                                cols="12"
                                md="4"
                                v-if="controlVisible"
                            >
                                <b-form-group
                                    :label="i18nT(`Job offers`)"
                                    label-for="jobOffers"
                                >
                                    <v-select
                                        v-model="jobOffer"
                                        multiple
                                        label="title"
                                        :options="jobOffers"
                                        :placeholder="i18nT(`All`)"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="4"
                                v-if="controlVisible"
                            >
                                <b-form-group
                                    :label="i18nT(`Skills`)"
                                    label-for="matchSkills"
                                >
                                    <v-select
                                        v-model="matchSkill"
                                        multiple
                                        label="title"
                                        :options="matchSkills"
                                        :placeholder="i18nT(`All`)"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="4"
                                v-if="controlVisible"
                            >
                                <b-form-group
                                    :label="i18nT(`Labels`)"
                                    label-for="filterLabels"
                                >
                                    <v-select
                                        v-model="filterLabels"
                                        multiple
                                        label="title"
                                        :options="labelOptions"
                                        :placeholder="i18nT(`All`)"
                                    />
                                </b-form-group>
                            </b-col>
                            <!--<b-col cols="12" md="4">
                              <b-form-group label="Match Folders" label-for="matchFolders">
                                <v-select
                                  v-model="matchFolder"
                                  label="title"
                                  :options="matchFolders"
                                />
                              </b-form-group>
                          </b-col>-->
                        </b-row>
                    </div>
                </div>
            </div>

            <div>
                <div>
                    <div class="row mx-2">
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />

                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                :text="i18nT(`Move To`)"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0"
                                :dropleft="true"
                                :dropup="true"
                            >
                                <b-dropdown-group
                                    id="dropdown-group-1"
                                    header="Application Status"
                                >
                                    <b-dropdown-item
                                        v-for="stage in actionStages"
                                        :key="stage.label"
                                        @click="moveStage(stage)"
                                    >
                                    <span class="align-middle ml-50">
                                        {{stage.label.toUpperCase()}}
                                    </span>

                                    </b-dropdown-item>
                                </b-dropdown-group>
                            </b-dropdown>

                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0"
                                :dropleft="true"
                                :dropup="true"
                            >
                                <b-dropdown-item v-if="iCan('applications', 'write')">
                                    <feather-icon icon="TagIcon"/>
                                    <span class="align-middle ml-50">{{
                                            i18nT(`Add label`)
                                        }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="iCan('applications', 'write')">
                                    <feather-icon icon="UserXIcon"/>
                                    <span class="align-middle ml-50">{{
                                            i18nT(`Reject candidate`)
                                        }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item>
                                    <feather-icon icon="SendIcon"/>
                                    <span class="align-middle ml-50">{{
                                            i18nT(`Send message`)
                                        }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="iCan('applications', 'write')">
                                    <feather-icon icon="BriefcaseIcon"/>
                                    <span class="align-middle ml-50">{{
                                            i18nT(`Offer a position`)
                                        }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item v-if="iCan('applications', 'write')">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>

                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-table
                        ref="refDocumentListTable"
                        :items="fetchApplicants"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >
                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span> 
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.application.Id"
                            />
                        </template>

                        <template #cell(name)="data">
                            <div class="media">
                                <div class="media-aside align-self-center">
                                    <b-avatar
                                        :to="{
                                          name: 'view-application',
                                          params: { id: data.item.user.Id },
                                          query: {offerId: data.item.application.OfferId}
                                        }"
                                                            :src="data.item.user.ImageUrl"
                                                            :text="
                                          (data.item.user.FirstName
                                            ? data.item.user.FirstName.charAt(0)
                                            : '') +
                                          (data.item.user.LastName
                                            ? data.item.user.LastName.charAt(0)
                                            : '')
                                        "
                                        class="badge-light-primary"
                                    >
                                    </b-avatar>
                                </div>
                                <div class="media-body">
                                    <b-link
                                        :to="{
                                          name: 'view-application',
                                          params: { id: data.item.user.Id },
                                          query: {offerId: data.item.application.OfferId}
                                        }"
                                        class="font-weight-bold d-block text-nowrap"
                                    >
                                        {{ data.item.user.Label }}
                                    </b-link>
                                    <p class="mb-0">{{ data.item.user.Position }}</p>
                                </div>
                            </div>

                            <!--<b-link
                                  :to="{
                                    name: 'view-application',
                                    params: { id: data.item.user.Id },
                                  }"
                                  class="d-flex align-items-center font-weight-bold"
                                >
                                  <b-avatar
                                    :src="data.item.user.ImageUrl"
                                    :text="(data.item.user.FirstName ? data.item.user.FirstName.charAt(0) : '') + (data.item.user.LastName ? data.item.user.LastName.charAt(0) : '')"
                                    class="mr-50 badge-light-primary"
                                  >
                                  </b-avatar>
                                  {{ data.item.user.Label }}
                              </b-link>-->
                        </template>

                        <template #cell(skills)="data">
                            <div
                                v-for="(skill, index) in data.item.skills"
                                :key="`skill-${index}`"
                                class="badge badge-light-secondary badge-pill mr-25">
                                {{ skill.Skill }}
                            </div>
                        </template>

                        <template #cell(labels)="data">
                            <div
                                v-for="(label, index) in getLabels(data.item.labels)"
                                :key="index"
                                class="
                                  badge
                                  text-capitalize
                                  badge-light-primary badge-pill
                                  mr-25
                                "
                            >
                                {{ label.title }}
                            </div>
                        </template>

                        <template #cell(openings)="data">
                            {{ data.item.job_offer ? data.item.job_offer.Label : "" }}
                        </template>

                        <template #cell(status)="data">
                            {{ data.item.application.StageObj.Label }}
                        </template>

                        <template #cell(applied)="data">
                            {{ data.item.application.CreatedAt | date }}
                        </template>

                        <template #cell(updated)="data">
                            {{ data.item.application.CreatedAt | date }}
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >
                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>

                                <b-dropdown-item
                                    v-if="data.item.application.PublicationStatus !== 'ACTIVE' && iCan('applications', 'write')"
                                    :to="{  name: 'edit-application',
                                            params: { id: data.item.user.Id }
                                          }"
                                >
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item :to="{
                                        name: 'view-application',
                                        params: { id: data.item.user.Id },
                                        query: {offerId: data.item.application.OfferId}
                                      }">
                                    <feather-icon icon="EyeIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`View`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item @click="addLabel(data)"
                                                 v-if="iCan('applications', 'write')"
                                >
                                    <feather-icon icon="TagIcon"/>
                                    <span class="align-middle ml-50">{{
                                            i18nT(`Add label`)
                                        }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item :to="{ name: 'inbox-send',
                                                        params: { id: data.item.user.Id, software: software },
                                                 }"
                                >
                                    <feather-icon icon="SendIcon"/>
                                    <span class="align-middle ml-50">{{
                                            i18nT(`Send message`)
                                        }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="changeStage(data.item)"
                                                 v-if="iCan('applications', 'write')"
                                >
                                    <feather-icon icon="UserCheckIcon"/>
                                    <span class="align-middle ml-50">{{
                                            i18nT(`Change application status`)
                                        }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onPropose(data)">
                                    <feather-icon icon="MailIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Offer another position`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onAssign(data)">
                                    <feather-icon icon="BriefcaseIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Assign to position`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onDelete(data)"
                                                 v-if="iCan('applications', 'write')"
                                >
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{
                                            i18nT(`Delete`)
                                        }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                </div>
                <!-- Disable grid view -->
                <b-tabs
                    pills
                    align="center"
                    v-if="false"
                >
                    <b-tab active>
                        <template #title>
                            <feather-icon
                                icon="ListIcon"
                                size="24"
                                style="margin: 0"
                            />
                        </template>
                    </b-tab>

                    <b-tab>
                        <template
                            #title
                            style="text-align: center"
                        >
                            <feather-icon
                                icon="GridIcon"
                                size="24"
                                style="margin: 0"
                            />
                        </template>
                        <div class="task-container">
                            <b-row class="task-item">
                                <b-col
                                    cols="4"
                                    v-for="document in cvs"
                                    :key="document.Id"
                                >
                                    <b-card style="border: 1px solid rgba(0, 0, 0, 0.07)">
                                        <b-card-body class="p-0">
                                            <div
                                                class="cv-card"
                                                style="min-height: 180px"
                                            >
                                                <h3 @click="
                            navTo({
                              name: 'edit-document',
                              params: { id: document.Id },
                            })
                          ">
                                                    {{ document.user.Label }}
                                                </h3>
                                                <h5>{{ document.user.Position }}</h5>
                                                <span>{{ i18nT(`Skills`) }} </span><br/>
                                                <span>{{ i18nT(`Experience`) }}
                          {{ document.user.Experience }}
                          {{ i18nT(`year(s)`) }}</span><br/>
                                                <span>{{ i18nT(`Expected Salary`) }}
                          {{ document.user.RequiredSalary }}</span><br/>
                                                <span>Located in {{ document.user.City }}
                          {{ countries[document.user.Country] }}</span>
                                            </div>
                                        </b-card-body>

                                        <b-card-footer class="
                                                                pb-0
                                                                pt-1
                                                                pr-1
                                                                pl-1
                                                                mt-2
                                                                ml-n2
                                                                mr-n2
                                                                text-top text-right
                                                          "
                                                       v-if="iCan('applications', 'write')"
                                        >
                                            <b-dropdown
                                                variant="link"
                                                toggle-class="p-0"
                                                no-caret
                                            >
                                                <template #button-content>
                                                    <b-button> {{ i18nT(`Actions`) }}</b-button>
                                                </template>
                                                <b-dropdown-item :to="{
                                                        name: 'edit-document',
                                                        params: { id: document.Id },
                                                      }"
                                                        v-if="iCan('applications', 'write')"
                                                >
                                                    <feather-icon icon="EditIcon"/>
                                                    <span class="align-middle ml-50">{{
                                                            i18nT(`Edit`)
                                                        }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onCopy({ item: document })"
                                                                 v-if="iCan('applications', 'write')"
                                                >
                                                    <feather-icon icon="CopyIcon"/>
                                                    <span class="align-middle ml-50">{{
                                                            i18nT(`Copy`)
                                                        }}</span>
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="onDelete({ item: document })"
                                                                 v-if="iCan('applications', 'write')"
                                                >
                                                    <feather-icon
                                                        icon="TrashIcon"
                                                        color="red"
                                                    />
                                                    <span
                                                        class="align-middle ml-50"
                                                        style="color: red"
                                                    >{{
                                                            i18nT(`Delete`)
                                                        }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </b-card-footer>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }}
                            {{ dataMeta.from }} {{ i18nT(`to`) }}
                            {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
                            {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                                d-flex
                                align-items-center
                                justify-content-center justify-content-sm-end
                              "
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>

        <!-- MODAL PROPOSE ANOTHER POSITION -->
        <b-modal
            id="modal-propose"
            title="Offer a Position"
            ok-only
            ok-title="Send"
            no-close-on-backdrop
            @ok="validationPropose"
        >
            <validation-observer ref="proposeForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                label="Job Offer *"
                                label-for="jobOffer"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Job Offer"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="proposeJob"
                                        text-field="title"
                                        :options="jobOffers"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group
                                label="Subject *"
                                label-for="subject"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Subject"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="messageSubject"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group
                                label="Message *"
                                label-for="message"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Message"
                                    rules="required"
                                >
                                    <b-form-textarea
                                        v-model="message"
                                        rows="12"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL ASSIGN TO POSITION -->
        <b-modal
            id="modal-assign"
            no-close-on-backdrop
            ok-only
            :ok-title="i18nT(`Assign`)"
            :title="i18nT(`Assign to position`)"
            @ok="validationPropose"
        >
            <validation-observer ref="proposeForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                :label="i18nT(`Job Offer`)" class="required"
                                label-for="jobOffer"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Job Offer`)"
                                    rules="required"
                                >
                                    <b-form-select
                                        v-model="proposeJob"
                                        :options="jobOffers"
                                        :state="errors.length > 0 ? false:null"
                                        text-field="title"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>


        <!-- MODAL CHANGE STATUS -->
        <modal-change-status
            :candidate-details="targetCandidate"
            @changed="handleChangeStatus"
        ></modal-change-status>

        <!-- MODAL ADD LABEL -->
        <b-modal
            id="modal-label"
            :title="i18nT(`Add Label to the selection`)"
            ok-only
            ok-title="Save"
            no-close-on-backdrop
            @ok="validationLabels"
        >
            <validation-observer ref="labelForm">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <voerro-tags-input
                                element-id="tags"
                                v-model="selectedTags"
                                :existing-tags="tagsList"
                                :typeahead="true"
                                @blur="onBlur"
                            ></voerro-tags-input>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL PUT IN FOLDER -->
        <b-modal
            id="modal-add-to-folder"
            title="Put in Folder"
            ok-only
            ok-title="Save"
            no-close-on-backdrop
            @ok="addToFolderValidateForm"
        >
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                label="Folders"
                                label-for="folders"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Folders"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="folders"
                                        multiple
                                        label="title"
                                        :options="folderOptions"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

        <!-- MODAL REMOVE FROM FOLDER -->
        <b-modal
            id="modal-remove-from-folder"
            title="Remove from Folder"
            ok-only
            ok-title="Remove"
            no-close-on-backdrop
            @ok="validationRemoveForm"
        >
            <validation-observer ref="simpleRules">
                <b-form class="mt-2">
                    <b-row>
                        <b-col sm="12">
                            <b-form-group
                                label="Folders"
                                label-for="folders"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Folders"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="folders"
                                        multiple
                                        label="title"
                                        :options="removeFolderOptions"
                                        :state="errors.length > 0 ? false : null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    BAvatar,
    // BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    BLink,
    // BListGroup, BListGroupItem,
    BCardFooter,
    BCardBody,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BDropdownGroup,
    BForm,
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useApplicationsList from './useApplicationsList'
import {avatarText} from '@core/utils/filter'
import {
    dictToSelectArrayWithTitle,
    dictToSelectArray
} from '@core/utils/utils'
import router from '@/router'
const {i18n} = useI18Utils();
// import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import useAuth from '@/auth/useAuth'
import VoerroTagsInput from '@voerro/vue-tagsinput'
import Ripple from 'vue-ripple-directive'
import DatePicker from '@core/components/DatePicker.vue'
import {useUtils as useI18Utils} from "@core/libs/i18n";
import ModalChangeStatus from "@/views/candidate/Modal/ModalChangeStatus.vue";

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTabs,
        BTab,
        BCardFooter,
        BCardBody,
        BLink,
        vSelect,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
        BFormSelect,
        BFormInput,
        BFormTextarea,
        BDropdownGroup,
        BForm,
        VoerroTagsInput,
        BAvatar,
        DatePicker,
        ModalChangeStatus
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            jobOffers: [],
            matchSkills: [],
            labelOptions: [],
            matchFolders: [],
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            sendInvitation: false,
            countries: {},
            stages: [],
            actionStages: [],
            folderOptions: [],
            removeFolderOptions: [],
            proposeJob: '',
            messageSubject: '',
            message: '',
            candidateId: '',

            targetStage: 0,
            targetStageLabel: '',
            targetCandidate: {},

            changeStatus: false,
            statusChangeTemplate: null,
            changeStatusNotification: false,
            tags: [],
            software: useAuth.getCurrentSoftware(),
            folders: [],
            selectedTags: [],
            tagsList: [],
            hiringDate: null,
            evaluationPeriodEnd: null,
            ending: null,
            salaryNet: null,
            salaryGross: null,
            salaryType: null,
            salaryTypes: [],
            avalableFields: [
              {label: this.i18nT(`Skills`), key: 'skills', visible: true},
              {label: this.i18nT(`Labels`), key: 'labels', visible: true},
          ],
            controlVisible: true,
        }
    },
    created() {

        let storePreferences = useAuth.getColumnPreferences('applications')
          if(storePreferences) {
            this.avalableFields = storePreferences
          }

        this.getFilterOptions();

        // this.$http.get(`offers/filterOffers`).then(({data}) => {
        //     this.jobOffers = dictToSelectArrayWithTitle(data.data)
        //     // this.jobOffers.unshift({
        //     //   value: '',
        //     //   title: 'All'
        //     // })
        // })
        //
        // this.$http.get(`offers/jobSkills`).then(({data}) => {
        //     if (data.data) {
        //         this.matchSkills = data.data.map(type => ({
        //             value: type.id,
        //             title: type.label
        //         }))
        //     }
        // })
        //
        // this.$http.get(`settings/labels`).then(({data}) => {
        //     this.labelOptions = data.data.map(label => ({
        //         value: label.Id,
        //         title: label.Label
        //     }))
        // })

        // this.$http.get(`candidates/candidateFolders`).then(({data}) => {
        //     for (const property in data.data) {
        //         this.matchFolders.push({
        //             value: property,
        //             title: data.data[property]
        //         })
        //     }
        // })

        this.$http.get(`system/countries`).then(({data}) => {
            this.countries = data.data
        })

        this.$http.get(`candidates/stages`).then(({data}) => {
            this.actionStages = [...data.data]
            this.actionStages.shift()
            this.matchStage = router.currentRoute.params.stage === data.data[0].label.toUpperCase() ? "ALL" : router.currentRoute.params.stage
            this.stages = data.data.filter(stage => stage.count !== 0);
        })

        // this.$http.get(`candidates/candidateFolders`).then(({data}) => {
        //     const options = data.data
        //     delete options.any
        //     delete options.not
        //     this.folderOptions = dictToSelectArrayWithTitle(options)
        // })

        this.$http.get(`settings/labels`).then(({data}) => {
            this.tagsList = data.data.map(tag => ({
                key: tag.Id,
                value: tag.Label
            }))
        })

        this.$http.get(`system/salaryTypes`).then(({data}) => {
            this.salaryTypes = dictToSelectArray(data.data)
        })
        this.fetchApplicants(null, this.determineVisibility);
    },
    methods: {
        onBlur(event) {

          if(event && event.relatedTarget && event.relatedTarget.className && event.relatedTarget.className === "btn btn-primary") {
              this.selectedTags.push({key: '', value: event.target.value})
          }
        },
        getFilterOptions(){
          this.$http.get(`offers/jobSkills`).then(({data}) => {
            if (data.data) {
              this.matchSkills = data.data.map(type => ({
                value: type.id,
                title: type.label
              }))
            }
          })

          this.$http.get(`settings/labels`).then(({data}) => {
            this.labelOptions = data.data.map(label => ({
              value: label.Id,
              title: label.Label
            }))
          })

          this.$http.get(`offers/filterOffers`).then(({data}) => {
            this.jobOffers = dictToSelectArrayWithTitle(data.data)
            // this.jobOffers.unshift({
            //   value: '',
            //   title: 'All'
            // })
          })
        },
        determineVisibility(invoiceArray) {
          if(invoiceArray.length===0){
            this.controlVisible = false;
          }
        },
        handleChangeStatus(){
            this.changeStatus = false
            this.changeStatusNotification = true
            this.refetchData()
        },
        navTo(destination) {
            this.$router.push(destination)
        },
        removeLabel(label) {
            console.log(label)
        },
        getLabels(labelArray) {
            return dictToSelectArrayWithTitle(labelArray)
        },
        getTypeName(typeId) {
            const document = this.documentTypes.find(type => type.value == typeId)
            return document ? document.text : ''
        },
        getAssigneeName(assignee) {
            if (assignee == null) {
                return ''
            }

            return assignee.Label
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(element) {
            this.$http
                .post(`documents/copyDocument?document_id=${element.item.Id}`)
                .then(() => {
                    this.refetchData()
                })
        },
        onDelete(candidate) {
            this.$swal({
                title: i18n(`Are you sure?`),
                text: i18n(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: i18n(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result && result.isConfirmed) {
                    this.$http
                        .delete(`candidates/removeApplications?applications=${candidate.item.application.Id}`)
                        .then((res) => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        }).catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
                }
            })
        },
        onBulkCopy() {
            this.$http
                .post(
                    `documents/copyDocument?document_id=${this.selectedItems.join(',')}`
                )
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: i18n(`Are you sure?`),
                text: i18n(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: i18n(`Yes, delete these!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`candidates/removeApplications?applications=${this.selectedItems.join(',')}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.application.Id)
            } else {
                this.selectedItems = []
            }
        },
        onPutInFolder(data) {
            this.selectedItems = [data.item.application.Id]
            this.$bvModal.show('modal-add-to-folder')
        },
        onDeleteFromFolder(data) {
            if (!data.item.folders) {
                return
            }

            this.selectedItems = [data.item.application.Id]
            this.removeFolderOptions = dictToSelectArrayWithTitle(data.item.folders)
            this.$bvModal.show('modal-remove-from-folder')
        },
        validationRemoveForm(event) {
            event.preventDefault()
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    //TODO
                    const formData = new FormData()
                    formData.append('candidates', this.getCandidateIds().join(','))
                    formData.append(
                        'folders',
                        this.folders.map(folder => folder.value).join(',')
                    )
                    this.$http
                        .post('candidates/removeFromFolder', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.folders = []
                            this.$bvModal.hide('modal-remove-from-folder')
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        addToFolderValidateForm(event) {
            event.preventDefault()
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    //TODO
                    const formData = new FormData()
                    formData.append('candidates', this.getCandidateIds().join(','))
                    formData.append(
                        'folders',
                        this.folders.map(folder => folder.value).join(',')
                    )
                    this.$http
                        .post('candidates/addToFolder', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.folders = []
                            this.$bvModal.hide('modal-add-to-folder')
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onPropose(data) {
            this.sendInvitation = true;
            this.candidateId = data.item.user.Id
            this.$bvModal.show('modal-propose')
        },
        onAssign(data) {
            this.sendInvitation = false;
            this.candidateId = data.item.user.Id
            this.$bvModal.show('modal-assign')
        },
        validationPropose(event) {
            event.preventDefault()
            this.$refs.proposeForm.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    formData.append('offer_id', this.proposeJob)
                    formData.append('candidate_ids', this.candidateId)
                    if(this.sendInvitation){
                        formData.append('subject', this.messageSubject)
                        formData.append('message', this.message)
                    }
                    formData.append('send_message', this.sendInvitation ? 1 : 0);
                    this.$bvModal.hide('modal-propose')
                    this.$http
                        .post('candidates/proposeOffer', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        moveStage(stage) {
            this.targetCandidate = stage;
            console.log(this.targetCandidate)
            this.statusTemplateOptions =
                stage.message_templates.length > 0
                    ? stage.message_templates.map(e => (
                        {   value: e.id,
                            text: e.label,
                            message: e.message,
                            subject: e.subject
                        }))
                    : []

            this.statusChangeTemplate = stage.message_template
                ? stage.message_template.id
                : this.statusTemplateOptions[0]
            this.changeStatus = false
            this.$bvModal.show('modal-change-status')
        },
        changeStage(candidate) {
            this.targetCandidate = candidate;
            console.log("Candidate obj: ", candidate)
            this.targetStageLabel = candidate.application.PublicationStatus;

            //this.selectedItems.push(offerId)
            //this.changeStatus = true
            this.$bvModal.show('modal-change-status')
        },
        moveFolderBulk(folder) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`Are you sure you want to change the folder `)+ folder.title,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Confirm`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result) {
                    const formData = new FormData()
                    formData.append('candidates', this.getCandidateIds().join(','))
                    formData.append('folders', folder.value)
                    this.$http
                        .post('candidates/addToFolder', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.folders = []
                            this.$bvModal.hide('modal-add-to-folder')
                            this.refetchData()
                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        resetChangeStatus() {
            this.changeStatus = false
            this.targetStage = {}
        },
        addLabel(data) {
            this.selectedItems = [data.item.application.Id]
            this.tags = []
            this.$bvModal.show('modal-label')
        },
        validationLabels(event) {
            event.preventDefault()
            if (this.selectedTags.length > 0) {
              const tags = this.selectedTags
                  .reduce((acc, tagg) => {
                    if(tagg.key) {
                      acc.push(tagg.key)
                    } else if (tagg.value) {
                      acc.push(tagg.value)
                    }
                    return acc
                  }, [])
                  .join(',')
                const formData = new FormData()
                formData.append('freelancers', this.getCandidateIds().join(','))
                if(tags){
                formData.append('labels', tags)
                  }

                this.$http
                    .post('candidates/addLabels', formData)
                    .then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(res),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })

                        this.selectedTags = []
                        this.$bvModal.hide('modal-label')
                        this.getFilterOptions();
                        this.refetchData();

                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        })
                    })
            } else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Please add tags`),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })
            }
        },
        getCandidateIds() {
            return this.cvs
                .filter(cv => this.selectedItems.includes(cv.application.Id))
                .map(cv => cv.user.Id)
        },
        gotoStage(stage) {
            this.matchStage = stage.label.toUpperCase()
            router.push({name: 'applications', params: {stage: this.matchStage}})
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    watch: {
        proposeJob: function (val) {
            const jobOffer = this.jobOffers.find(offer => offer.value === val)
            this.message = this.i18nT(`Dear [candidate_name],

We would like to propose you the following job position(s) with us:
${window.location.protocol}//${
                window.location.host
            }/apply/${jobOffer.title.split(' ').join('-')}_${jobOffer.value}

Sincerely
Ilya Popivanov
Dea Solutions International`);
            this.messageSubject = jobOffer.title
        },
        targetStage: {
            handler: function (val) {
                this.changeStatusNotification = !this.changeStatusNotification
                this.statusTemplateOptions =
                    val.message_templates && val.message_templates.length > 0
                        ? val.message_templates.map(e => ({
                            value: e.id,
                            text: e.label,
                            message: e.message,
                            subject: e.subject
                        }))
                        : []
                if (this.statusTemplateOptions.length > 0)
                    this.statusChangeTemplate = this.statusTemplateOptions[0]
                let self = this
                setTimeout(() => {
                    self.changeStatusNotification = !self.changeStatusNotification
                }, 1)
            },
            deep: true
        }
    },
    setup() {
        const {
            fetchApplicants,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            jobOffer,
            matchSkill,
            filterLabels,
            matchFolder,
            matchStage,
            cvs,
            refetchData
        } = useApplicationsList()

        return {
            fetchApplicants,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            jobOffer,
            matchSkill,
            filterLabels,
            matchFolder,
            matchStage,
            avatarText,
            cvs,
            refetchData,
            required
        }
    },
    computed: {
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your applications. No applications have been created yet.`) + "</h4>"
        },
        computedColumns() {

            let col = [...this.tableColumns]

            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('applications', this.avalableFields);

            return col
        },
    }

}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}

.cv-card {
    min-height: 180px;

    span {
        font-size: 12px;
    }
}

.stage-container {
    background: white;
    border: 1px solid gray;
    border-right: 0px;
    overflow: hidden;

    .stageLink {
        border: none !important;
        border-radius: 0px;
    }

    .stageLink:focus,
    .stageLink:active {
        background-color: $primary !important;
    }

    .count {
        font-size: 30px;
        color: #5e5873;
        font-weight: 300;
    }

    .stage-label {
        color: #5e5873;
        font-size: 10px;
    }

    .stage-avatar {
        color: white;
        width: 14px;
        height: 14px;
        background: #5e5873;
        display: inline-block;
        margin-right: 4px;
        padding-top: 1px;
        font-size: 10px;
    }
}

.stage-container:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.stage-container:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: solid 1px gray;
}

.active-stage {
    background-color: $primary !important;

    span {
        color: white !important;
    }
}

.menu-section-title {
    padding: 16px;
    font-size: 8px;
}

/* The input */
.tags-input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.tags-input input {
    flex: 1;
    background: transparent;
    border: none;
}

.tags-input input:focus {
    outline: none;
}

.tags-input input[type='text'] {
    color: #495057;
}

.tags-input-wrapper-default {
    padding: 0.5em 0.25em;

    background: #fff;

    border: 1px solid transparent;
    border-radius: 0.25em;
    border-color: #dbdbdb;
}

.tags-input-wrapper-default.active {
    border: 1px solid #8bbafe;
    box-shadow: 0 0 0 0.2em rgba(13, 110, 253, 0.25);
    outline: 0 none;
}

/* The tag badges & the remove icon */
.tags-input span {
    margin-right: 0.3em;
}

.tags-input-remove {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    right: 0.3em;
    top: 0.3em;
    padding: 0.5em;
    overflow: hidden;
}

.tags-input-remove:focus {
    outline: none;
}

.tags-input-remove:before,
.tags-input-remove:after {
    content: '';
    position: absolute;
    width: 75%;
    left: 0.15em;
    background: #5dc282;

    height: 2px;
    margin-top: -1px;
}

.tags-input-remove:before {
    transform: rotate(45deg);
}

.tags-input-remove:after {
    transform: rotate(-45deg);
}

/* Tag badge styles */
.tags-input-badge {
    position: relative;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tags-input-badge-pill {
    padding-right: 1.25em;
    padding-left: 0.6em;
    border-radius: 10em;
}

.tags-input-badge-pill.disabled {
    padding-right: 0.6em;
}

.tags-input-badge-selected-default {
    color: #212529;
    background-color: #f0f1f2;
}

/* Typeahead */
.typeahead-hide-btn {
    color: #999 !important;
    font-style: italic;
}

/* Typeahead - badges */
.typeahead-badges > span {
    margin-top: 0.5em;
}

.typeahead-badges > span {
    cursor: pointer;
    margin-right: 0.3em;
}

/* Typeahead - dropdown */
.typeahead-dropdown {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 1000;
}

.typeahead-dropdown li {
    padding: 0.25em 1em;
    cursor: pointer;
}
</style>
